import { axiosClient } from "../axios";

const baseUrl = "/api/docs/documents";

export function get(enterpriseId, type) {
  return axiosClient.get(`${baseUrl}/${enterpriseId}/${type}`);
}

export function save(data) {
  return axiosClient.post(`${baseUrl}/save`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function remove(data) {
  return axiosClient.post(`${baseUrl}/delete`, data);
}

export function download(id) {
  const config = {
    responseType: "blob",
  };
  return axiosClient.get(`${baseUrl}/${id}/download`, config);
}
