import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import user from "./modules/user";
import workbook from "./modules/workbook";
import utils from "./modules/utils";
import header from "./modules/header";
import snackbar from "./modules/snackbar";
import spreadsheet from "./modules/spreadsheet";
import businessPlan from "./modules/business-plan";
import scenario from "./modules/scenario";
import shared from "./modules/shared";

const modules = {
  user: user,
  workbook: workbook,
  utils: utils,
  header: header,
  snackbar: snackbar,
  spreadsheet: spreadsheet,
  businessPlan: businessPlan,
  scenario: scenario,
  shared: shared,
};

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: modules,
  plugins: [createPersistedState({ paths: ["user", "shared"] })],
});

export default store;
