import Store from '@/store'
import Grid from "@/components/grid/grid.js";

export default class Ast {
  static functions = new Set([
    'eq',
    'g',
    'ge',
    'if',
    'min',
    'max',
    'cat',
    'ifEqual',
    'ifEqualOrHigher',
    'ifEqualOrLessWithFirstArgAbs',
    'ppmt',
    'ipmt',
    'sum',
    'minArg',
    'maxArg',
    'avg',
    'maxArgWithoutZeros',
    'minArgWithoutZeros',
    'avgWithoutZeros',
    'dsrcSolver',
    'size',
    'checkStore',
    'ifError',
    'ifErrorWithNullOrInfinityOrUndefined',
    'ifErrorManagedIndeterminateZeroDividedZero',
    'ifErrorManagedIndeterminateZeroDividedZeroWithGreaterCondition',
    'logTest',
    'invisibleSixthAndSeventhTable',
    'sumWithNullArgArray',
    'ifHigher',
    'searchLeasingYear',
    'produceGrossRateKeyString'
  ])

  static fn(op, args) {
    if (op == 'eq') return Ast.eq(args[0], args[1])
    if (op == 'g') return Ast.g(args[0], args[1])
    if (op == 'ge') return Ast.ge(args[0], args[1])
    if (op == 'if') return Ast.if(args[0], args[1], args[2])
    if (op == 'min') return Ast.min(args[0], args[1])
    if (op == 'max') return Ast.max(args[0], args[1])
    if (op == 'cat') return Ast.cat(args[0], args[1])
    if (op == 'ifEqual') return Ast.ifEqual(args[0], args[1], args[2], args[3])
    if (op == 'ifEqualOrHigher') return Ast.ifEqualOrHigher(args[0], args[1], args[2], args[3])
    if (op == 'ifHigher') return Ast.ifHigher(args[0], args[1], args[2], args[3])
    if (op == 'ifEqualOrLessWithFirstArgAbs') return Ast.ifEqualOrLessWithFirstArgAbs(args[0], args[1], args[2], args[3])
    if (op == 'ppmt') return Ast.ppmt(args[0], args[1], args[2], args[3], args[4], args[5])
    if (op == 'ipmt') return Ast.ipmt(args[0], args[1], args[2], args[3], args[4], args[5])
    if (op == 'sum') return Ast.sum(args)
    if (op == 'minArg') return Ast.minArg(args)
    if (op == 'maxArg') return Ast.maxArg(args)
    if (op == 'avg') return Ast.avg(args)
    if (op == 'minArgWithoutZeros') return Ast.minArgWithoutZeros(args)
    if (op == 'maxArgWithoutZeros') return Ast.maxArgWithoutZeros(args)
    if (op == 'avgWithoutZeros') return Ast.avgWithoutZeros(args)
    if (op == 'dsrcSolver') return Ast.dsrcSolver(args[0])
    if (op == 'size') return Ast.size(args)
    if (op == 'checkStore') return Ast.checkStore(args[0])
    if (op == 'ifError') return Ast.ifError(args[0], args[1], args[2])
    if (op == 'ifErrorWithNullOrInfinityOrUndefined') return Ast.ifErrorWithNullOrInfinityOrUndefined(args[0], args[1])
    if (op == 'ifErrorManagedIndeterminateZeroDividedZero') return Ast.ifErrorManagedIndeterminateZeroDividedZero(args[0], args[1], args[2], args[3])
    if (op == 'ifErrorManagedIndeterminateZeroDividedZeroWithGreaterCondition') return Ast.ifErrorManagedIndeterminateZeroDividedZeroWithGreaterCondition(args[0], args[1], args[2], args[3], args[4], args[5])
    if (op == 'logTest') return Ast.logTest(args[0], args[1], args[2], args[3], args[4], args[5])
    if (op == 'invisibleSixthAndSeventhTable') return Ast.invisibleSixthAndSeventhTable(args[0], args[1], args[2], args[3])
    if (op == 'sumWithNullArgArray') return Ast.sumWithNullArgArray(args)
    if (op == 'searchLeasingYear') return Ast.searchLeasingYear(args)
    if (op == 'produceGrossRateKeyString') return Ast.produceGrossRateKeyString(args[0])
  }

  static eq(arg1, arg2) {
    return arg1 == arg2 ? 1 : 0
  }

  static g(arg1, arg2) {
    return arg1 > arg2 ? 1 : 0
  }

  static ge(arg1, arg2) {
    return arg1 >= arg2 ? 1 : 0
  }

  static if(arg1, arg2, arg3) {
    return arg1 > 0 ? arg2 : arg3
  }

  static ifEqual(arg1, arg2, arg3, arg4) {
    return arg1 == arg2 ? arg3 : arg4
  }

  static ifEqualOrHigher(arg1, arg2, arg3, arg4) {
    return arg1 >= arg2 ? arg3 : arg4
  }

  static ifHigher(arg1, arg2, arg3, arg4) {
    return arg1 > arg2 ? arg3 : arg4
  }

  static ifEqualOrLessWithFirstArgAbs(arg1, arg2, arg3, arg4) {
    return Math.abs(arg1) <= arg2 ? arg3 : arg4
  }

  static ifError(arg1, arg2, arg3) {
    return arg1 != 0 ? arg2 : arg3
  }

  static ifErrorWithNullOrInfinityOrUndefined(arg1, arg2) {
    if (isNaN(arg1) || arg1 === null || arg1 === Infinity || arg1 === - Infinity) {
      return arg2;
    } else {
      return arg1
    }
  }

  static ifErrorManagedIndeterminateZeroDividedZero(arg1, arg2, arg3, arg4) {
    return !(arg1 == 0 && arg2 == 0) ? arg3 : arg4
  }

  static ifErrorManagedIndeterminateZeroDividedZeroWithGreaterCondition(arg1, arg2, arg3, arg4, arg5, arg6) {
    if (arg1 > 0) {
      return !(arg2 == 0 && arg3 == 0) ? arg4 : arg5
    } else {
      return arg6
    }
  }

  static min(arg1, arg2) {
    return arg1 > arg2 ? arg2 : arg1
  }

  static max(arg1, arg2) {
    return arg1 > arg2 ? arg1 : arg2
  }

  static cat(arg1, arg2) {
    return `${arg1}${arg2}`
  }

  static sum(argArray) {
    let flatArray = argArray.flat()
    let result = flatArray.reduce((a, b) => {
      return a + b
    }, 0)
    return result
  }

  static sumWithNullArgArray(argArray) {
    let flatArray = argArray.flat()
    if (flatArray.length > 1) {
      flatArray.pop()
    }
    let result = flatArray.reduce((a, b) => {
      return a + b
    }, 0)
    return result
  }

  static sumObjectValuesOfTableSchema(object, lastPYear) {
    const firstFYear = Number(lastPYear) + 1;
		let sum = 0;

    for (const key in object) {
			const keyInt = Number(key);
      if (keyInt >= firstFYear && !isNaN(keyInt)) {
        sum += object[key].x
      }
    }

		return sum
  }

  static getObjectValuesOfTableSchema(object, lastPYear) {
    const firstFYear = Number(lastPYear) + 1;
		const array = [];

    for (const key in object) {
			const keyInt = Number(key);
      if (keyInt >= firstFYear && !isNaN(keyInt)) {
        array.push(object[key].x)
      }
    }

    return array
  }

  static getLastPastYearValueOfTableSchema(object, lastPYear) {
    return object[lastPYear].x
  }

  static getFirstFutureYearValueOfTableSchema(object, lastPYear) {
    const firstFYear = +lastPYear + 1;
		return object[firstFYear].x
  }

  static minArg(argArray) {
    let flatArray = argArray.flat()
    return Math.min(...flatArray)
  }

  static maxArg(argArray) {
    let flatArray = argArray.flat()
    return Math.max(...flatArray)
  }

  static avg(argArray) {
    let flatArray = argArray.flat()
    return flatArray.reduce((a, b) => a + b, 0) / flatArray.length;
  }

  static minArgWithoutZeros(argArray) {
    let flatArray = argArray.flat()
    flatArray = flatArray.filter((argArray) => {
      return argArray != 0;
    })
    return Math.min(...flatArray)
  }

  static maxArgWithoutZeros(argArray) {
    let flatArray = argArray.flat()
    flatArray = flatArray.filter((argArray) => {
      return argArray != 0;
    })
    return Math.max(...flatArray)
  }

  static avgWithoutZeros(argArray) {
    let flatArray = argArray.flat()
    flatArray = flatArray.filter((argArray) => {
      return argArray != 0;
    })
    return flatArray.reduce((a, b) => a + b, 0) / flatArray.length;
  }

  static logTest(rate, per, nper, pv, fv, type) {
    console.log("rate", rate);
    console.log("per", per);
    console.log("nper", nper);
    console.log("pv", pv);
    console.log("fv", fv);
    console.log("type", type);
    return this.ipmt(rate, per, nper, pv, fv, type)
  }

  static invisibleSixthAndSeventhTable(fYearCardinalNumber, yearsDur, preammYears, previousYearsSum) {
    let exp1 = (fYearCardinalNumber <= (yearsDur + preammYears)) ? 1 : 0;
    let exp2 = ((fYearCardinalNumber - preammYears) > 0) ? 1 : 0;
    let result = exp1 * exp2
    if(fYearCardinalNumber == 1) return result;
    let exp3 = (fYearCardinalNumber > (yearsDur + preammYears)) ? 1 : 0;
    let exp4 = (fYearCardinalNumber - preammYears > 0) ? 1 : 0;
    result = result + ((yearsDur - previousYearsSum) * exp3) * exp4
    return result;
  }

  static size(argArray) {
    let flatArray = argArray.flat()
    return flatArray.length;
  }

  static searchLeasingYear(argArray) {
    let flatArray = argArray.flat();
    let indexes = [];
    for (let index = 0; index < flatArray.length; index++) {
      const element = flatArray[index];
      if(element != 0) {
        indexes.push(index + 1)
      }
    }
    if (indexes.length > 0) {
      return Math.max(...indexes);
    } else {
      return 0
    }
  }

  static produceGrossRateKeyString(string) {
    return "Tasso lordo " + string;
  }

  static pmt(rate, nper, pv, fv, type) {
    if (!fv) fv = 0;
    if (!type) type = 0;

    if (rate == 0) return -(pv + fv) / nper;

    var pvif = Math.pow(1 + rate, nper);
    var pmt = rate / (pvif - 1) * -(pv * pvif + fv);

    if (type == 1) {
      pmt /= (1 + rate);
    };

    return pmt;
  }

  static ipmt(rate, per, nper, pv, fv, type) {
    let perMinusOne = per - 1;

    if (per == 0) {
      return 0;
    }

    if (per >= nper + 1) {
      return 0;
    }

    let pmt = this.pmt(rate, nper, pv, fv, type);
    var tmp = Math.pow(1 + rate, perMinusOne);
    let result = 0 - (pv * tmp * rate + pmt * (tmp - 1));
    if (result > 0) {
      return 0;
    }
    return result;
  }

  static ppmt(rate, per, nper, pv, fv, type) {
    if (per < 1 || (per >= nper + 1)) return 0; //NaN
    var pmt = this.pmt(rate, nper, pv, fv, type);
    var ipmt = this.ipmt(rate, per, nper, pv, fv, type);
    return (pmt - ipmt);
  }

  static async automaticCellsCompilation(easyModeTemplate) {
    let workbook = Store.getters["workbook/getWorkbook"];

    easyModeTemplate.defaultValues.forEach((defValue) => {
      let defValueTable = workbook.tableMap.get(defValue.table);

      if(workbook.data[defValue.c]) {
        workbook.data[defValue.c].forEach((colKey) => {
          defValueTable.sheet[defValueTable.rows.key2idx.get(defValue.r)][colKey].x;

          let ri = defValueTable.rows.key2idx.get(defValue.r);
          let ck = colKey.toString();
          let ci = defValueTable.cols.key2idx.get(ck);

          Grid.setValue(Store.getters["workbook/getWorkbook"], defValueTable, ri, ck, Grid.evaluateFormula(defValue, workbook, ci, ri));
          Grid.updateRelatedCells(Store.getters["workbook/getWorkbook"], defValueTable, Store.getters["workbook/getWorkbook"].data, ri, ck);
        })
      } else if(workbook.properties[defValue.c]) {
        workbook.properties[defValue.c].forEach((colKey) => {
          defValueTable.sheet[defValueTable.rows.key2idx.get(defValue.r)][colKey].x;

          let ri = defValueTable.rows.key2idx.get(defValue.r);
          let ck = colKey.toString();
          let ci = defValueTable.cols.key2idx.get(ck);

          Grid.setValue(Store.getters["workbook/getWorkbook"], defValueTable, ri, ck, Grid.evaluateFormula(defValue, workbook, ci, ri));
          Grid.updateRelatedCells(Store.getters["workbook/getWorkbook"], defValueTable, Store.getters["workbook/getWorkbook"].data, ri, ck);
        })
      }
    });

    Grid.updateRowData(Store.getters["workbook/getWorkbook"]);
  }

  static dsrcSolver(dsrcAvgTarget, lastPYear) {
    let ri = 14;
    let ck = lastPYear
    let additionalDebt;
    let dsrcAvgActualObject = { ...Store.getters["workbook/getSelectedTable"].sheet[10] }
    let dsrcAvgActual = this.getLastPastYearValueOfTableSchema(dsrcAvgActualObject, lastPYear)

    let param;
    if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 10000000) {
      param = 1 / 1000000
    } else if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 1000000) {
      param = 1 / 100000
    } else if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 100000) {
      param = 1 / 10000
    } else if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 10000) {
      param = 1 / 1000
    } else if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 1000) {
      param = 1 / 100
    } else if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 100) {
      param = 1 / 10
    } else if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 1) {
      param = 100
    } else if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 0.1) {
      param = 1000
    } else if (Math.abs(dsrcAvgTarget - dsrcAvgActual) >= 0.01) {
      param = 10000
    } else {
      param = 100000
    }

    let dynamicIndex = Math.abs(parseInt((dsrcAvgTarget - dsrcAvgActual) * param)) >= 1 ? Math.abs(parseInt((dsrcAvgTarget - dsrcAvgActual) * param)) : 1;

    for (let i = 0; i < dynamicIndex; i++) {
      let availableCashSumObject = { ...Store.getters["workbook/getSelectedTable"].sheet[3] }
      let availableCashSum = this.sumObjectValuesOfTableSchema(availableCashSumObject, lastPYear)

      let principalInstallmentSumObject = { ...Store.getters["workbook/getSelectedTable"].sheet[4] }
      let principalInstallmentSum = this.sumObjectValuesOfTableSchema(principalInstallmentSumObject, lastPYear)

      let taxInterestRateSumObject = { ...Store.getters["workbook/getSelectedTable"].sheet[7] }
      let taxInterestRateSum = this.sumObjectValuesOfTableSchema(taxInterestRateSumObject, lastPYear)

      let dsrcAvgActualObject = { ...Store.getters["workbook/getSelectedTable"].sheet[10] }
      let dsrcAvgActual = this.getLastPastYearValueOfTableSchema(dsrcAvgActualObject, lastPYear)

      let additionalDebtObject = { ...Store.getters["workbook/getSelectedTable"].sheet[14] }
      additionalDebt = this.getLastPastYearValueOfTableSchema(additionalDebtObject, lastPYear)
      let oldAdditionalDebt = additionalDebt

      additionalDebt = (additionalDebt - Math.pow((principalInstallmentSum + taxInterestRateSum), 2) * ((dsrcAvgActual + (dsrcAvgTarget - dsrcAvgActual) * ((i + 1) / dynamicIndex)) - dsrcAvgActual) / availableCashSum);

      Grid.setValue(Store.getters["workbook/getWorkbook"], Store.getters["workbook/getSelectedTable"], ri, ck, additionalDebt);
      Grid.updateRelatedCells(Store.getters["workbook/getWorkbook"], Store.getters["workbook/getSelectedTable"], Store.getters["workbook/getWorkbook"].data, ri, ck);

      if (oldAdditionalDebt.toFixed(4) === additionalDebt.toFixed(4)) {
        break
      }
    }

    Grid.updateRowData(Store.getters["workbook/getWorkbook"]);

    return additionalDebt;
  }

  static dsrcMinSolver(dsrcMinTarget, lastPYear) {
    let ri = 14;
    let ck = lastPYear
    let additionalDebt;
    let dsrcMinActualObject = { ...Store.getters["workbook/getSelectedTable"].sheet[11] }
    let dsrcMinActual = this.getLastPastYearValueOfTableSchema(dsrcMinActualObject, lastPYear)

    let param;
    if (Math.abs(dsrcMinTarget - dsrcMinActual) >= 10000000) {
      param = 1 / 10000
    } else if (Math.abs(dsrcMinTarget - dsrcMinActual) >= 1000000) {
      param = 1 / 1000
    } else if (Math.abs(dsrcMinTarget - dsrcMinActual) >= 100000) {
      param = 1 / 100
    } else if (Math.abs(dsrcMinTarget - dsrcMinActual) >= 10000) {
      param = 1 / 10
    } else if (Math.abs(dsrcMinTarget - dsrcMinActual) >= 10) {
      param = 100
    } else if (Math.abs(dsrcMinTarget - dsrcMinActual) >= 1) {
      param = 100 * 4
    }  else if (Math.abs(dsrcMinTarget - dsrcMinActual) >= 0.1) {
      param = 1000 * 2
    } else if (Math.abs(dsrcMinTarget - dsrcMinActual) >= 0.01) {
      param = 10000
    } else {
      param = 100000
    }

    let dynamicIndex = Math.abs(parseInt((dsrcMinTarget - dsrcMinActual) * param)) >= 1 ? Math.abs(parseInt((dsrcMinTarget - dsrcMinActual) * param * 1.2)) : 1;

    for (let i = 0; i < dynamicIndex; i++) {
      let availableCashArrayObject = { ...Store.getters["workbook/getSelectedTable"].sheet[3] }
      let availableCashArray = this.getObjectValuesOfTableSchema(availableCashArrayObject, lastPYear)
      let availableCashActual = availableCashArray.at(-1)

      let principalInstallmentArrayObject = { ...Store.getters["workbook/getSelectedTable"].sheet[4] }
      let principalInstallmentArray = this.getObjectValuesOfTableSchema(principalInstallmentArrayObject, lastPYear)
      let principalInstallmentActual = principalInstallmentArray.at(-1)

      let taxInterestRateArrayObject = { ...Store.getters["workbook/getSelectedTable"].sheet[7] }
      let taxInterestRateArray = this.getObjectValuesOfTableSchema(taxInterestRateArrayObject, lastPYear)
      let taxInterestRateActual = taxInterestRateArray.at(-1)

      let dsrcMinActualObject = { ...Store.getters["workbook/getSelectedTable"].sheet[11] }
      let dsrcMinActual = this.getLastPastYearValueOfTableSchema(dsrcMinActualObject, lastPYear)

      let additionalDebtObject = { ...Store.getters["workbook/getSelectedTable"].sheet[14] }
      additionalDebt = this.getLastPastYearValueOfTableSchema(additionalDebtObject, lastPYear)

      let oldAdditionalDebt = additionalDebt;

      additionalDebt = (additionalDebt - Math.pow((principalInstallmentActual + taxInterestRateActual), 2) * ((dsrcMinActual + (dsrcMinTarget - dsrcMinActual) * ((i + 1) / dynamicIndex)) - dsrcMinActual) / availableCashActual);

      Grid.setValue(Store.getters["workbook/getWorkbook"], Store.getters["workbook/getSelectedTable"], ri, ck, additionalDebt);
      Grid.updateRelatedCells(Store.getters["workbook/getWorkbook"], Store.getters["workbook/getSelectedTable"], Store.getters["workbook/getWorkbook"].data, ri, ck);

      if (oldAdditionalDebt.toFixed(4) === additionalDebt.toFixed(4)) {
        break
      }
    }

    Grid.updateRowData(Store.getters["workbook/getWorkbook"]);

    return additionalDebt;
  }

  static dsrcMaxSolver(dsrcMaxTarget, lastPYear) {
    let ri = 14;
    let ck = lastPYear
    let additionalDebt;
    let dsrcMaxActualObject = { ...Store.getters["workbook/getSelectedTable"].sheet[12] }
    let dsrcMaxActual = this.getLastPastYearValueOfTableSchema(dsrcMaxActualObject, lastPYear)

    let preammYearsObject = { ...Store.getters["workbook/getSelectedTable"].sheet[16] }
    let preammYears = this.getLastPastYearValueOfTableSchema(preammYearsObject, lastPYear);
    let maxDscrIndex = preammYears - 1;

    let param;

    if (Math.abs(dsrcMaxTarget - dsrcMaxActual) >= 1000000) {
      param = 1 / 1000
    } else if (Math.abs(dsrcMaxTarget - dsrcMaxActual) >= 100000) {
      param = 1 / 100
    } else if (Math.abs(dsrcMaxTarget - dsrcMaxActual) >= 10000) {
      param = 1 / 10
    } else if (Math.abs(dsrcMaxTarget - dsrcMaxActual) >= 1000) {
      param = 3
    } else if (Math.abs(dsrcMaxTarget - dsrcMaxActual) >= 10) {
      param = 100
    } else if (Math.abs(dsrcMaxTarget - dsrcMaxActual) >= 1) {
      param = 100 * 4
    }  else if (Math.abs(dsrcMaxTarget - dsrcMaxActual) >= 0.1) {
      param = 1000 * 2
    } else if (Math.abs(dsrcMaxTarget - dsrcMaxActual) >= 0.01) {
      param = 10000
    } else {
      param = 100000
    }

    let dynamicIndex = Math.abs(parseInt((dsrcMaxTarget - dsrcMaxActual) * param)) >= 1 ? Math.abs(parseInt((dsrcMaxTarget - dsrcMaxActual) * param)) : 1;

    for (let i = 0; i < dynamicIndex; i++) {
      let availableCashArrayObject = { ...Store.getters["workbook/getSelectedTable"].sheet[3] }
      let availableCashArray = this.getObjectValuesOfTableSchema(availableCashArrayObject, lastPYear)
      let availableCashActual = availableCashArray[maxDscrIndex]

      let principalInstallmentArrayObject = { ...Store.getters["workbook/getSelectedTable"].sheet[4] }
      let principalInstallmentArray = this.getObjectValuesOfTableSchema(principalInstallmentArrayObject, lastPYear)
      let principalInstallmentActual = principalInstallmentArray[maxDscrIndex]

      let taxInterestRateArrayObject = { ...Store.getters["workbook/getSelectedTable"].sheet[7] }
      let taxInterestRateArray = this.getObjectValuesOfTableSchema(taxInterestRateArrayObject, lastPYear)
      let taxInterestRateActual = taxInterestRateArray[maxDscrIndex]

      let dsrcMaxActualObject = { ...Store.getters["workbook/getSelectedTable"].sheet[12] }
      let dsrcMaxActual = this.getLastPastYearValueOfTableSchema(dsrcMaxActualObject, lastPYear)

      let additionalDebtObject = { ...Store.getters["workbook/getSelectedTable"].sheet[14] }
      additionalDebt = this.getLastPastYearValueOfTableSchema(additionalDebtObject, lastPYear)

      let oldAdditionalDebt = additionalDebt;

      additionalDebt = (additionalDebt - Math.pow((principalInstallmentActual + taxInterestRateActual), 2) * ((dsrcMaxActual + (dsrcMaxTarget - dsrcMaxActual) * ((i + 1) / dynamicIndex)) - dsrcMaxActual) / availableCashActual);

      Grid.setValue(Store.getters["workbook/getWorkbook"], Store.getters["workbook/getSelectedTable"], ri, ck, additionalDebt);
      Grid.updateRelatedCells(Store.getters["workbook/getWorkbook"], Store.getters["workbook/getSelectedTable"], Store.getters["workbook/getWorkbook"].data, ri, ck);

      if (oldAdditionalDebt.toFixed(4) === additionalDebt.toFixed(4)) {
        break
      }
    }

    Grid.updateRowData(Store.getters["workbook/getWorkbook"]);

    return additionalDebt;
  }

  static additionalDebtCapacityTabButton(selectFieldInput, textFieldInput) {
    const workbook = Store.getters["workbook/getWorkbook"];
    const lastPYear = workbook.data.lastPYear[0];

    if (selectFieldInput == 0) {
      this.dsrcSolver(textFieldInput, lastPYear)
    } else if (selectFieldInput == 1) {
      this.dsrcMinSolver(textFieldInput, lastPYear)
    } else if (selectFieldInput == 2) {
      this.dsrcMaxSolver(textFieldInput, lastPYear)
    }
  }

  static checkStore(number) {
    return parseInt(number)
  }

  static unpackRange(range) {
    if (Ast.isNumber(range)) {
      return [range]
    }
    if (Ast.isArray(range)) {
      let refs = []
      for (let i = range[0]; i < range[1]; i++) refs.push(i)
      return refs
    }
    throw {
      msg: "expected number or range",
      obj: range
    }
  }

  static evaluate(schema, data, ast, value) {
    if (Ast.isObject(ast)) {
      if (ast.op == '+') return Ast.evaluate(schema, data, ast["1"], value) + Ast.evaluate(schema, data, ast["2"], value)
      if (ast.op == '-') return Ast.evaluate(schema, data, ast["1"], value) - Ast.evaluate(schema, data, ast["2"], value)
      if (ast.op == '*') return Ast.evaluate(schema, data, ast["1"], value) * Ast.evaluate(schema, data, ast["2"], value)
      if (ast.op == '/') return Ast.evaluate(schema, data, ast["1"], value) / Ast.evaluate(schema, data, ast["2"], value)
      if (ast.op == 'o') {
        if (data[ast.identifier] == undefined) return 0.0
        let obj = data[ast.identifier]
        for (let key of ast.args) {
          if (obj[key] == undefined) return 0.0
          obj = obj[key]
        }
        return obj
      }
      if (ast.op == 'c') {
        let table = schema.tableMap.get(ast.table)
        let isRange = Ast.isArray(ast.r) || Ast.isArray(ast.c)
        if (isRange) {
          let rows = Ast.unpackRange(ast.r)
          let cols = Ast.unpackRange(ast.c)
          let x = []
          for (let r of rows) {
            for (let c of cols) {
              let ck = table.cols.keys[c]
              let row = table.sheet[r]
              if (ck == undefined || row == undefined) x.push(0.0)
              else x.push(row[ck].x)
            }
          }
          return x
        }
        else {
          let ck = table.cols.keys[ast.c]
          let row = table.sheet[ast.r]
          if (ck == undefined) return 0.0
          if (row == undefined) return 0.0
          let cell = row[ck]
          return cell.x
        }
      }
      if (Ast.isFunction(ast)) {
        let arg_vals = ast.args.map(e => Ast.evaluate(schema, data, e, value))
        return Ast.fn(ast.op, arg_vals)
      }
    }
    else if (Ast.isArray(ast)) {
      return ast.map(x => Ast.evaluate(schema, data, x, value))
    }
    else if (Ast.isNumber(ast)) return ast
    else if (Ast.isString(ast)) {
      if (ast == '&v') return value
      else if (ast == '&n') return NaN
      else {
        return ast
      }
    }
    return undefined
  }

  static getPrecedents(ast) {
    let list = []
    Ast.getPrecedentsImp(list, ast)
    return list
  }

  static getPrecedentsImp(list, ast) {
    if (Ast.isObject(ast)) {
      if (ast.op == 'c') {
        let rows = Ast.unpackRange(ast.r)
        let cols = Ast.unpackRange(ast.c)
        for (let r of rows) {
          for (let c of cols) {
            list.push({
              table: ast.table,
              r: r,
              c: c
            })
          }

        }
      }
      else if (ast.op == '+' || ast.op == '-' || ast.op == '*' || ast.op == '/') {
        Ast.getPrecedentsImp(list, ast["1"])
        Ast.getPrecedentsImp(list, ast["2"])
      }
      else if (Ast.isFunction(ast)) {
        for (let arg of ast.args) Ast.getPrecedentsImp(list, arg)
      }
    }
  }

  static getProperty(workbook, id) {
    let obj = workbook.data[id]
    if (obj == undefined) obj = workbook.properties[id]
    return obj
  }

  static translateString(workbook, t, ast, ri, ci, axis) {
    if (ast == "$r") return ri
    if (ast == "$c") return ci
    let rj = t.rows.ids[ri].index
    let cj = t.cols.ids[ci].index
    if (ast == "@r") return rj
    if (ast == "@c") return cj
    let rk = t.rows.keys[ri]
    let ck = t.cols.keys[ci]
    if (ast == "#r") return rk
    if (ast == "#c") return ck
    if (ast[0] == '%') {
      return axis.key2idx.get(ast.substring(1))
    }
    if (ast[0] == "^") {
      let obj = Ast.getProperty(workbook, ast.substring(1))
      if (!Ast.isArray(obj)) throw {
        msg: "expected array",
        obj: ast
      }
      return obj.length
    }
    return ast
  }

  static calculateRefs(arg) {
    if (Ast.isNumber(arg)) return arg
    else if (Ast.isString(arg)) return arg
    else if (Ast.isObject(arg)) {
      if (arg.op == '+') return Ast.calculateRefs(arg["1"]) + Ast.calculateRefs(arg["2"])
      if (arg.op == '-') return Ast.calculateRefs(arg["1"]) - Ast.calculateRefs(arg["2"])
      if (arg.op == '*') return Ast.calculateRefs(arg["1"]) * Ast.calculateRefs(arg["2"])
      if (arg.op == '/') return Ast.calculateRefs(arg["1"]) / Ast.calculateRefs(arg["2"])
    }
    else if (Ast.isArray(arg)) {
      return arg.map(x => Ast.calculateRefs(x))
    }
    throw {
      msg: "semantic error",
      obj: arg
    }
  }

  static translateAll(workbook, ast, t, ri, ci, axis) {
    if (Ast.isObject(ast)) {
      if (ast.op == '+' || ast.op == '-' || ast.op == '*' || ast.op == '/') {
        ast["1"] = Ast.translateAll(workbook, ast["1"], t, ri, ci)
        ast["2"] = Ast.translateAll(workbook, ast["2"], t, ri, ci)
      }
      if (Ast.isFunction(ast)) {
        for (let i in ast.args) ast.args[i] = Ast.translateAll(workbook, ast.args[i], t, ri, ci)
      }
      if (ast.op == 'o' && ast.args) {
        for (let i = 0; i < ast.args.length; i++) {
          ast.args[i] = Ast.translateAll(workbook, ast.args[i], t, ri, ci)
          ast.args[i] = Ast.calculateRefs(ast.args[i])
        }
      }
      if (ast.op == 'c') {
        let table = workbook.tableMap.get(ast.table)
        ast.r = Ast.translateAll(workbook, ast.r, t, ri, ci, table.rows)
        ast.c = Ast.translateAll(workbook, ast.c, t, ri, ci, table.cols)
        ast.r = Ast.calculateRefs(ast.r)
        ast.c = Ast.calculateRefs(ast.c)
      }
    }
    else if (Ast.isArray(ast)) {
      ast = ast.map(x => Ast.translateAll(workbook, x, t, ri, ci))
    }
    else if (Ast.isString(ast)) {
      return Ast.translateString(workbook, t, ast, ri, ci, axis)
    }
    return ast
  }

  static translateFormula(workbook, ast, table, ri, ci) {
    ast = Ast.translateAll(workbook, ast, table, ri, ci)
    return ast
  }

  static isObject(obj) {
    return (typeof obj === 'object') && !Array.isArray(obj)
  }
  static isArray(obj) {
    return (typeof obj === 'object') && Array.isArray(obj)
  }
  static isString(obj) {
    return typeof obj === 'string'
  }
  static isNumber(obj) {
    return typeof obj === 'number'
  }
  static isFunction(obj) {
    if (!obj.op) return false
    return Ast.functions.has(obj.op)
  }
}